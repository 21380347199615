import React from 'react';
import { useTranslation } from 'react-i18next';

function SearchDrink(props) {
    const { className, categories, setResult, setIsSearching } = props;

    const { t } = useTranslation();

    function search(e) {
        if (e.target.value != "" && e.target.value != null) {
            let result = [];

            categories.forEach(category => {
                const drinks = category.drinks.filter(drink => drink.name.includes(e.target.value));
                if (drinks.length > 0) {
                    result.push({ drinks, name: category.name });
                }
            });

            setResult(result);
            setIsSearching(true);
        } else {
            setResult(categories);
            setIsSearching(false);
        }
    };

    return (
        <div>
            <input
                className={className}
                placeholder={t("searchdrink.input")}
                onChange={search} />
        </div>
    );
}

export default SearchDrink;
