import React from 'react';
import MorganaRow from '../list/MorganaRow';
import MorganaTabs from './MorganaTabs';
import NewTabs from './NewTabs';
import RedCafeTabs from './RedCafeTabs';

function TabRouter(props) {
    const { restaurant, hasOrders } = props;

    if (restaurant.id == 9207)
        return restaurant.wrapCourses && restaurant.wrapCourses.length > 0 ?
            restaurant.wrapCourses.map((wrapCourse, i) => <MorganaRow course={wrapCourse} index={i} key={i} />)
            : <p className="text-center">Nessun piatto disponibile</p>

    switch (process.env.REACT_APP_NAMESPACE) {
        case "mycia": case "aperolspritz": case "campari": case "camparisoda": case "averna": case "wild": case "espolon": case "bulldog": case "braulio": case "crodinoxl": case "redbull": case "pago": case "sanbitter":
            return <NewTabs hasOrders={hasOrders} />
        case "morgana":
            return restaurant.wrapCourses && restaurant.wrapCourses.length > 0 ?
                restaurant.wrapCourses.map((wrapCourse, i) => <MorganaRow course={wrapCourse} index={i} key={i} />)
                : <p className="text-center">Nessun piatto disponibile</p>
        case "redcafe":
            return <RedCafeTabs hasOrders={hasOrders} />
    }

}

export default TabRouter;
